import React from "react";
import {Col, Collapse, Row, Typography} from "@bezlimit/bezlimit-ui";
import {observer} from "mobx-react";
import {useTranslation} from "react-i18next";

import Store from "../../../../../stores";
import "./style.scss";
// services
import BonusesService from "../../../../../services/BonusesService";
import _ from "lodash";

export default React.memo(
    observer(function Base() {
        const {Panel} = Collapse;
        const {t} = useTranslation();
        const levelDealer = Store.user?.level?.level;

        const rateLoyalty = BonusesService.rateLoyalty + " %";
        const summaLevel =
            (!_.isNil(Store.user.loyalty) ? BonusesService.rateLoyalty : 0) +
            BonusesService.rateBasicLevel +
            " %";
        const summaBonuses =
            BonusesService.rateBasicPersonal +
            BonusesService.ratePhoneBl +
            (!_.isNil(Store.user.loyalty) ? BonusesService.rateLoyaltyPersonal : 0) +
            " %";

        const headerLoyaltyName = (test: number) => {
            switch (test) {
                case 0:
                    return (
                        <Row>
                            <Col className="bl-my-bonuses-futurePaymentSum-wrapper-base-rateLoyalty">
                                <Typography as="div" size={14} color="#ffffff">
                                    {t("Дилерские ")}
                                    <p>{summaLevel}</p>
                                </Typography>
                            </Col>
                            <Col>
                                <Typography as="div" size={14} color="#ffffff">
                                    {t("Личные ")}
                                    <p>{summaBonuses}</p>
                                </Typography>
                            </Col>
                        </Row>
                    );
                case 1:
                    return (
                        <Row>
                            <Col className="bl-my-bonuses-futurePaymentSum-wrapper-base-rateLoyalty">
                                <Typography as="div" size={14} color="#ffffff">
                                    {t("Процент за активации ")}
                                    <p>{summaBonuses}</p>
                                </Typography>
                            </Col>
                        </Row>
                    );

                default:
                    break;
            }
        };

        const rateBasic = BonusesService.rateBasicPersonal + " %";
        const rateBasicLevel = BonusesService.rateBasicLevel + " %";
        const ratePhoneBl = BonusesService.ratePhoneBl + " %";
        const rateLoyaltyPersonal = BonusesService.rateLoyaltyPersonal + " %";

        const bodyLoyaltyName = (test: number) => {
            switch (test) {
                case 0:
                    return (
                        <>
                            <Typography as="div" size={12} color="#ffffff">
                                {t("Базовая ставка ")}
                                <p>{rateBasicLevel}</p>
                            </Typography>
                            {!_.isNil(Store.user.loyalty) && (
                                <Typography as="div" size={12} color="#ffffff">
                                    {t("Статус лояльности ")}
                                    <p>{rateLoyalty}</p>
                                </Typography>
                            )}
                        </>
                    );
                case 1:
                    return (
                        <>
                            <Typography as="div" size={12} color="#ffffff">
                                {t("Базовая ставка ")}
                                <p>{rateBasic}</p>
                            </Typography>
                            <Typography as="div" size={12} color="#ffffff">
                                {t("За номер Безлимит ")}
                                <p>{ratePhoneBl}</p>
                            </Typography>
                            {!_.isNil(Store.user.loyalty) && (
                                <Typography as="div" size={12} color="#ffffff">
                                    {t("Статус лояльности ")}
                                    <p>{rateLoyaltyPersonal}</p>
                                </Typography>
                            )}
                        </>
                    );
                default:
                    break;
            }
        };

        return (
            <>
                {levelDealer === 0 || levelDealer === 1 ? (
                    <Collapse ghost expandIconPosition="end">
                        <Panel header={headerLoyaltyName(0)} key="1">
                            <Row gutter={16}>
                                <Col xs={12} lg={12} className="bl-my-bonuses--wrapper-ant-col">
                                    {bodyLoyaltyName(0)}
                                </Col>
                                <Col xs={12} lg={12} className="bl-my-bonuses--wrapper-ant-col">
                                    {bodyLoyaltyName(1)}
                                </Col>
                            </Row>
                        </Panel>
                    </Collapse>
                ) : (
                    <Collapse
                        ghost
                        className="bl-my-bonuses-futurePaymentSum-wrapper-base-rateLoyalty-two-dealers"
                        expandIconPosition="end"
                    >
                        <Panel header={headerLoyaltyName(1)} key="2">
                            {bodyLoyaltyName(1)}
                        </Panel>
                    </Collapse>
                )}
            </>
        );
    })
);
