import React, {useContext} from "react";
import {Typography} from "@bezlimit/bezlimit-ui";
import {useTranslation} from "react-i18next";
import {createSearchParams, useNavigate} from "react-router-dom";
import _ from "lodash";
import "./style.scss";
// core
import {LayoutContext} from "../../../../../../../core/Contexts";

export default React.memo(function Free({id, type, phone, search}: IProps) {
    const contextLayout = useContext(LayoutContext);
    const {t} = useTranslation();
    const navigate = useNavigate();

    const onClick = async () => {
        navigate({
            pathname: `/reservation/${id}/${phone}`
        });
    };

    return (
        <Typography
            as="span"
            size={14}
            color="#fff"
            underline
            className="bl-numbers-list-actions-free"
            onClick={onClick}
        >
            {_.isEqual(type, 1) &&
                t(`${_.gt(contextLayout?.width ?? 0, 768) ? "Забронировать на 3 часа" : "3 часа"}`)}
            {_.isEqual(type, 4) &&
                t(`${_.gt(contextLayout?.width ?? 0, 768) ? "Забронировать на 1 день" : "1 день"}`)}
            {_.isEqual(type, 5) &&
                t(`${_.gt(contextLayout?.width ?? 0, 768) ? "Забронировать на 5 дней" : "5 дней"}`)}
        </Typography>
    );
});

interface IProps {
    phone: number;
    type: number;
    id: number;
    search: URLSearchParams;
}
