import {Back, Col, Row, Space, Title, Typography} from "@bezlimit/bezlimit-ui";
import {useCallback, useContext, useEffect, useState} from "react";
import NumberFormat from "react-number-format";
import {useNavigate, useParams} from "react-router-dom";
import _ from "lodash";
import "./style.scss";
// core
import {IReservations} from "../../../../../core/models/Reservations";
import {LayoutContext} from "../../../../../core/Contexts";
import {ITariffsAvailable} from "../../../../../core/models/Tariffs";
// services
import ReservationsService from "../../../../../services/ReservationsService";
import TariffsService from "../../../../../services/TariffsService";
// components
import BookReservationCreateForm from "../../../../forms/bookReservationCreate";
import Loading from "../../../../blocks/ui/loading";
// portal
import DefaultPortal from "../../../../portals/default";
import Nps from "./nps";

export default function BookPageReservations() {
    const contextLayout = useContext(LayoutContext);
    const [npsPhone, setNpsPhone] = useState<string>();
    const [isInit, setIsInit] = useState(false);
    const [reservations, setReservations] = useState<IReservations>();
    const [tariffs, setTariffs] = useState<ITariffsAvailable[]>([]);
    const {phone} = useParams();
    const navigate = useNavigate();

    const getReservationsByPnone = useCallback(async (phone: number) => {
        try {
            const [reservations, tariffs] = await Promise.all([
                ReservationsService.getReservationsByPnone({phone_number: phone}),
                TariffsService.getTariffsByPhone({phone})
            ]);

            const reservation = _.head(reservations.items);
            setReservations(reservation);
            setNpsPhone(_.get(reservation, "additional_data.nps.phone"));

            if (!_.includes([8], reservation?.phone.type)) {
                setTariffs(tariffs);
            }
        } finally {
            setIsInit(true);
        }
    }, []);

    useEffect(() => {
        if (phone) {
            getReservationsByPnone(+phone);
        }
    }, [phone]);

    return (
        <>
            {isInit ? (
                <>
                    <Back navigate={() => navigate("/")} />
                    <Row
                        justify="center"
                        gutter={[16, _.gte(contextLayout?.width ?? 0, 768) ? 24 : 20]}
                    >
                        <Col span={24}>
                            <Nps
                                npsPhone={npsPhone}
                                reservations={reservations}
                                setNpsPhone={setNpsPhone}
                            />
                        </Col>
                        <Col span={24}>
                            <Row
                                justify="center"
                                align="middle"
                                gutter={[16, 16]}
                                className="text-center"
                            >
                                <Col span={24}>
                                    <Space
                                        direction="vertical"
                                        size={_.gte(contextLayout?.width ?? 0, 768) ? 16 : 0}
                                    >
                                        <Typography as="div" className="text-center">
                                            <Title className="bl-title">
                                                <NumberFormat
                                                    readOnly={true}
                                                    value={phone}
                                                    format="### ### ## ##"
                                                    displayType="text"
                                                />
                                            </Title>
                                        </Typography>
                                    </Space>
                                </Col>
                            </Row>
                        </Col>
                        {reservations ? (
                            <Col span={24}>
                                <BookReservationCreateForm
                                    tariffs={tariffs}
                                    reservations={reservations}
                                    edit={true}
                                    npsPhone={npsPhone}
                                />
                                {/* <BookReservationEditForm
                                    reservations={reservations}
                                    tariffs={tariffs}
                                /> */}
                            </Col>
                        ) : (
                            <Col span={24}>
                                <BookReservationCreateForm
                                    tariffs={tariffs}
                                    edit={false}
                                    npsPhone={npsPhone}
                                />
                            </Col>
                        )}
                    </Row>
                </>
            ) : (
                <DefaultPortal>
                    <Loading />
                </DefaultPortal>
            )}
        </>
    );
}
