import React, {useContext} from "react";
import {Block, Button, Space, Typography} from "@bezlimit/bezlimit-ui";
import {useTranslation} from "react-i18next";
import {useNavigate} from "react-router-dom";
import _ from "lodash";
import "./style.scss";
// core
import {LayoutContext} from "../../../../core/Contexts";

export default React.memo(function DatabaseOfNumbers() {
    const contextLayout = useContext(LayoutContext);
    const {t} = useTranslation();
    const navigate = useNavigate();

    const onClick = () => {
        navigate("/numbers");
    };

    return (
        <Typography as="div" className="bl-database-of-numbers">
            <Block title={t("База номеров")}>
                <Space size={_.gte(contextLayout?.width, 992) ?? 0 ? 22 : 11} direction="vertical">
                    <Typography
                        as="div"
                        size={_.gte(contextLayout?.width, 992) ?? 0 ? 17 : 14}
                        color="rgba(255, 255, 255, 0.7)"
                        className="text-helper"
                    >
                        {t(
                            "Начните работу с номерами, зарабатывайте бонусы, выводите настоящими рублями"
                        )}
                    </Typography>
                    <Button onClick={onClick} type="primary">
                        {t("База номеров")}
                    </Button>
                </Space>
            </Block>
        </Typography>
    );
});
