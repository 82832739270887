import {SetStateAction} from "react";
import {Block, Button, Col, Icon, Row, Space, Typography} from "@bezlimit/bezlimit-ui";
import {observer} from "mobx-react";
import _ from "lodash";
//images
import ImgBtnComplete from "../../../../../images/btn_complete.png";
//core
import {ISessions} from "../../../../../core/models/Sessions";
import date from "../../../../../core/functions/date";
//services
import SessionsServise from "../../../../../services/Sessions";
import phoneProfile from "../../../../../images/phone_profile.png";

export default observer(function DesctopNoActive({i, loading, setLoading}: Props) {
    const dateFormat: Intl.DateTimeFormatOptions = {
        year: "numeric",
        month: "numeric",
        day: "numeric"
    };

    return (
        <Block>
            <Row>
                <Col span={1} className="icon-devicess">
                    {_.isEqual(i.user_agent.type, "mobile") ? (
                        <img src={phoneProfile} width={18} alt="logo" />
                    ) : _.isEqual(i.user_agent.type, "web") &&
                    _.isEqual(i.user_agent.os_name, "linux") ? (
                        <Icon name={"linux_session"} size={"26px"}/>
                    ) : _.isEqual(i.user_agent.type, "web") &&
                    _.isEqual(i.user_agent.os_name, "windows") ? (
                        <Icon name={"Win_profile"} size={"26px"}/>
                    ) : (_.isEqual(i.user_agent.type, "web") &&
                        _.isEqual(i.user_agent.os_name, "macOS")) ||
                    _.isEqual(i.user_agent.os_name, "MacOS") ? (
                        <Icon name={"mac_session"} size={"26px"}/>
                    ) : _.isEqual(i.user_agent.type, "web") &&
                    _.isEqual(i.user_agent.os_name, "android") ? (
                        <Icon name={"android_session"} size={"26px"}/>
                    ) : _.isEqual(i.user_agent.type, "web") &&
                    _.isEqual(i.user_agent.os_name, "iOS") ? (
                        <Icon name={"apple_session"} size={"26px"}/>
                    ) : (
                        <Icon name={"other_session"} size={"26px"}/>
                    )}
                </Col>
                <Col span={17} className="col-content-block">
                    <Space direction="vertical" size={8}>
                        <Typography as="div" className="title-devices" size={18}>
                            {_.isEqual(i.user_agent.name, "") || _.isEqual(i.user_agent, null)
                                ? "Неизвестное устройство"
                                : `${i.user_agent.name}, ${i.user_agent.os_name}`}
                        </Typography>
                        <Typography as="div" className="sub-title-devices" size={16}>
                            {i.user_agent.type}
                        </Typography>
                        {_.isEqual(i.location, null) || _.isEqual(i.location, "") ? (
                            <Typography as="div" className="discription-devices" size={14}>
                                {i.ip} <Icon name={"dots"} size={"5px"}/>{" "}
                                {date(_.get(i, "created_at"), dateFormat)}
                            </Typography>
                        ) : (
                            <Typography as="div" className="discription-devices" size={14}>
                                {i.ip} - {i.location} <Icon name={"dots"} size={"5px"}/>{" "}
                                {date(_.get(i, "created_at"), dateFormat)}
                            </Typography>
                        )}
                    </Space>
                </Col>
                <Col span={6}>
                    <Button
                        className="btn-complite"
                        onClick={async () => {
                            try {
                                setLoading(true);
                                await SessionsServise.deleteSession(i.id);
                            } finally {
                                setLoading(false);
                            }
                        }}
                    >
                        <img src={ImgBtnComplete} width={74} alt="logo"/>
                    </Button>
                </Col>
            </Row>
        </Block>
    );
});

interface IProps {
    i: ISessions;
    loading: boolean;
    setLoading: React.Dispatch<SetStateAction<boolean>>;
}

type Props = IProps;
