import {Col, Icon, Row, Space, Typography} from "@bezlimit/bezlimit-ui";
import {observer} from "mobx-react";
import _ from "lodash";
import "./style.scss";
import {SetStateAction} from "react";
//core
import date from "../../../../../core/functions/date";
import {ISessions} from "../../../../../core/models/Sessions";
//services
import SessionsServise from "../../../../../services/Sessions";
import phoneProfile from "../../../../../images/phone_profile.png";

export default observer(function MobileNoActive({i, loading, setLoading}: Props) {
    const dateFormat: Intl.DateTimeFormatOptions = {
        year: "numeric",
        month: "numeric",
        day: "numeric"
    };
    return (
        <Typography as="div" className="conteiner">
            <Typography as="div" className="bl-group-sessins">
                <Typography as="div" className="group-list-sessins">
                    <Row>
                        <Col span={3} className="icon-devicess">
                            {_.isEqual(i.user_agent.type, "mobile") ? (
                                <img src={phoneProfile} width={18} alt="logo" />
                            ) : _.isEqual(i.user_agent.type, "web") &&
                            _.isEqual(i.user_agent.os_name, "linux") ? (
                                <Icon name={"linux_session"} size={"26px"}/>
                            ) : _.isEqual(i.user_agent.type, "web") &&
                            _.isEqual(i.user_agent.os_name, "windows") ? (
                                <Icon name={"Win_profile"} size={"26px"}/>
                            ) : (_.isEqual(i.user_agent.type, "web") &&
                                _.isEqual(i.user_agent.os_name, "macOS")) ||
                            _.isEqual(i.user_agent.os_name, "MacOS") ? (
                                <Icon name={"mac_session"} size={"26px"}/>
                            ) : _.isEqual(i.user_agent.type, "web") &&
                            _.isEqual(i.user_agent.os_name, "android") ? (
                                <Icon name={"android_session"} size={"26px"}/>
                            ) : _.isEqual(i.user_agent.type, "web") &&
                            _.isEqual(i.user_agent.os_name, "iOS") ? (
                                <Icon name={"apple_session"} size={"26px"}/>
                            ) : (
                                <Icon name={"other_session"} size={"26px"}/>
                            )}
                        </Col>
                        <Col span={21} className="col-content-block">
                            <Space direction="vertical" size={8}>
                                <Typography as="div" className="title-devices" size={16}>
                                    {_.isEqual(i.user_agent.name, "")
                                        ? "Неизвестное устройство"
                                        : `${i.user_agent.name}, ${i.user_agent.os_name}`}
                                </Typography>
                                <Typography as="div" className="sub-title-devices" size={14}>
                                    {i.user_agent.type}
                                </Typography>
                                {_.isEqual(i.location, null) || _.isEqual(i.location, "") ? (
                                    <Typography as="div" className="discription-devices" size={14}>
                                        {i.ip} <Icon name={"dots"} size={"5px"}/>{" "}
                                        {date(_.get(i, "created_at"), dateFormat)}
                                    </Typography>
                                ) : (
                                    <Typography as="div" className="discription-devices" size={14}>
                                        {i.ip} - {i.location} <Icon name={"dots"} size={"5px"}/>{" "}
                                        {date(_.get(i, "created_at"), dateFormat)}
                                    </Typography>
                                )}
                            </Space>
                        </Col>
                        <Typography
                            className="btn-complite"
                            onClick={async () => {
                                try {
                                    setLoading(true);
                                    await SessionsServise.deleteSession(i.id);
                                } finally {
                                    setLoading(false);
                                }
                            }}
                        >
                            Завершить
                        </Typography>
                    </Row>
                </Typography>
            </Typography>
        </Typography>
    );
});

interface IProps {
    i: ISessions;
    loading: boolean;
    setLoading: React.Dispatch<SetStateAction<boolean>>;
}

type Props = IProps;
