import React, {useEffect, useRef, useState} from "react";
import {Typography} from "@bezlimit/bezlimit-ui";
import {useTranslation} from "react-i18next";
import moment from "moment";
import _ from "lodash";
// core
import {IReservation} from "../../../../../../../core/models/Phones";
// components
import Time from "./time";
// services
import PhonesBookingService from "../../../../../../../services/PhonesBookingService";

export default React.memo(function Duration({reservation, suffix, pay_type}: IProps) {
    const {t} = useTranslation();
    const timeout: any = useRef();
    const [state, setState] = useState(<></>);

    useEffect(() => {
        if (!_.isNil(reservation)) {
            clearTimeout(timeout.current);
            const {removed_at} = reservation;
            const interval = 1000;
            const eventTime = moment(removed_at).unix();
            const currentTime = moment().unix();
            const diffTime = eventTime - currentTime;
            let duration: any = moment.duration(diffTime * 1000, "milliseconds");

            timeout.current = setTimeout(function () {
                duration = moment.duration(duration - interval, "milliseconds");
                if (_.gt(duration.asMilliseconds(), 0)) {
                    setState(<Time duration={duration} suffix={suffix} />);
                } else {
                    clearTimeout(timeout.current);
                    setState(
                        <Typography
                            as="div"
                            color="#fff"
                            size={15}
                            className="process-text gold"
                            fontFamily="alumna"
                        >
                            {t(
                                _.isEqual(pay_type, "super_link")
                                    ? "В процессе удаления"
                                    : "В процессе продления"
                            )}
                        </Typography>
                    );
                }
            }, interval);
        }

        return () => {
            clearTimeout(timeout.current);
        };
    }, [state]);

    return (
        <>
            {PhonesBookingService.isInProcess(reservation) ? (
                <Typography
                    as="div"
                    color="#fff"
                    size={15}
                    className="process-text gold"
                    fontFamily="alumna"
                >
                    {_.isEqual(reservation?.pay_type, "free") || _.isEqual(pay_type, "super_link")
                        ? t("В процессе удаления")
                        : t("В процессе продления")}
                </Typography>
            ) : (
                <Typography as="div" className="duration-container">
                    {state}
                </Typography>
            )}
        </>
    );
});

interface IProps {
    reservation: IReservation | undefined;
    suffix?: boolean;
    pay_type?: string;
}
