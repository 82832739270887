import {observer} from "mobx-react";
import {useTranslation} from "react-i18next";
import {Block, Button, Col, Row, Space, Typography} from "@bezlimit/bezlimit-ui";
import "./style.scss";
//images
import AttentionSessions from "../../../../../../images/attention_sessions.png";

export default observer(function ModalCloseAll({onClickCloseAll, onCancel}: Props) {
    const {t} = useTranslation();

    return (
        <Block className="bl-modal-attention">
            <Row>
                <Space direction="vertical" size={24}>
                    <Col span={24}>
                        <img src={AttentionSessions} width={88} alt="logo"/>
                    </Col>
                    <Col span={24}>
                        <Typography as="div" className="title">
                            Завершить все сеансы
                        </Typography>
                    </Col>
                    <Col span={24}>
                        <Typography as="div" className="sub-title">
                            {t("После завершения сеансов у вас \nостанется один активный сеанс")}
                        </Typography>
                    </Col>

                    <Col span={24}>
                        <Button type="primary" onClick={onClickCloseAll}>
                            Завершить все сеансы
                        </Button>
                    </Col>
                    <Col span={24}>
                        <Button type="text" onClick={onCancel}>
                            Закрыть
                        </Button>
                    </Col>
                </Space>
            </Row>
        </Block>
    );
});

interface IProps {
    onClickCloseAll: () => void;
    onCancel: () => void;
}

type Props = IProps;
