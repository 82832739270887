import {useCallback, useContext, useEffect, useRef, useState} from "react";
import {Back, Col, Row, Tabs, Title, Typography, notification} from "@bezlimit/bezlimit-ui";
import {useLocation, useNavigate} from "react-router-dom";
import {useTranslation} from "react-i18next";
import {isMobile} from "react-device-detect";
import _ from "lodash";
import "./style.scss";
// components
import ReservationList from "../../../blocks/book/reservationList";
import ActivationList from "../../../blocks/book/activationList";
import {svgIconBookSprite} from "../../../blocks/ui/svgIconBookSprite";
import Header from "../../../blocks/header";
// core
import {LayoutContext} from "../../../../core/Contexts";
import ReservationsService from "../../../../services/ReservationsService";
import {
    IMeta,
    IReservations,
    IReservationsBookRequest,
    IReservationsRequest
} from "../../../../core/models/Reservations";

export default function BookPage() {
    const time = useRef<any>(null);
    const contextLayout = useContext(LayoutContext);
    const {t} = useTranslation();
    const [activeKey, setActiveKey] = useState("activation");
    const [items, setItems] = useState<IReservations[]>([]);
    const [meta, setMeta] = useState<IMeta>();
    const [filterRequest, setFilterRequest] = useState<IReservationsRequest | undefined>();
    const [isFilter, setIsFilter] = useState(false);
    const [isInit, setIsInit] = useState(false);
    const [isInitEmpty, setIsInitEmpty] = useState(false);
    const lacation = useLocation();
    const navigate = useNavigate();
    const [shimmer, setShimmer] = useState(true);

    const onTabClick = (key: string) => {
        window.history.pushState("", "", key);
        setActiveKey(key);
        getReservationsByIsActivated({key}, true, true);
        setShimmer(false);
    };

    const updateList = (
        request?: IReservationsRequest,
        loading: boolean = false,
        reset: boolean = false
    ) => {
        getReservationsByIsActivated({key: activeKey, ...request}, loading, reset);
    };

    const getStatusNPS = async () => {
        return await ReservationsService.getReservationsByIsActivated({
            status: "nps"
        });
    };

    const getReservationsByIsActivated = useCallback(
        async (
            {key, ...request}: IReservationsBookRequest,
            loading: boolean = false,
            reset: boolean = false
        ) => {
            clearTimeout(time.current);
            const {items: data, _meta}: any =
                await ReservationsService.getReservationsByIsActivated(
                    {
                        ...request,
                        status: ReservationsService.isActivated(key),
                        per_page: 100
                    },
                    loading
                );

            if (_.isEqual(key, "activation")) {
                const {items: data2}: any = await getStatusNPS();
                _.forEach(data, (i) => {
                    if (_.find(data2, (i2) => i2.id === i.id)) {
                        i.nps = true;
                    }
                });
            }

            if (!isInit && (_.isEqual(key, "activation") || _.isEqual(key, "reservation"))) {
                setIsInit(true);

                if (!_.size(data)) {
                    setIsInitEmpty(true);
                }
            }

            if (
                !_.isEmpty(request) &&
                (_.get(request, "phone_number") ||
                    _.get(request, "activated_from") ||
                    _.get(request, "activated_to"))
            ) {
                setIsFilter(true);
            } else {
                setIsFilter(false);
            }

            if (!_.size(items) || reset) {
                setItems(data);
            } else {
                setItems([...items, ...data]);
            }

            setMeta(_meta);

            if (_.find(data, ({status}) => _.isEqual(status, "queue"))) {
                time.current = setTimeout(() => {
                    getReservationsByIsActivated({key}, false, false);
                }, 3000);
            }
        },
        [isInit, meta, items, shimmer]
    );

    useEffect(() => {
        if (shimmer) {
            setTimeout(() => {
                setShimmer(false);
            }, 1000);
        }
    }, [shimmer]);

    useEffect(() => {
        const key = lacation.pathname.slice(1);
        setActiveKey(key);
        getReservationsByIsActivated({key}, false, false);

        return () => {
            clearTimeout(time.current);
        };
    }, []);

    const toggle = () => {
        (document.body.querySelector(".paralax-btn") as any)?.click();
    };

    return (
        <>
            {isMobile && <Header toggle={toggle} />}
            {svgIconBookSprite()}
            <Back navigate={() => navigate("/")} />
            <Row justify="center" gutter={[16, _.gte(contextLayout?.width ?? 0, 768) ? 56 : 32]}>
                <Col span={24}>
                    <Row justify="center" align="middle" gutter={[16, 16]}>
                        <Col span={24}>
                            <Typography as="div" className="text-center">
                                <Title
                                    title={
                                        activeKey === "activation" ? "Мои активации" : "Мои брони"
                                    }
                                />
                            </Typography>
                        </Col>
                    </Row>
                </Col>
                <Col span={24}>
                    <Tabs
                        className="tabs-container"
                        centered
                        tabPosition="top"
                        animated={false}
                        activeKey={activeKey}
                        onTabClick={onTabClick}
                        tabPanes={[
                            {
                                title: t("Мои брони"),
                                key: "reservation",
                                content: _.isEqual(activeKey, "reservation") && (
                                    <ReservationList
                                        setShimmer={setShimmer}
                                        shimmer={shimmer}
                                        items={items}
                                        updateList={updateList}
                                        activeKey={activeKey}
                                        setItems={setItems}
                                        firstPageRender={isInit}
                                        meta={meta}
                                        setMeta={setMeta}
                                        getReservationsByIsActivated={getReservationsByIsActivated}
                                        filterRequest={filterRequest}
                                    />
                                )
                            },
                            {
                                title: t("Активации"),
                                key: "activation",
                                content: _.isEqual(activeKey, "activation") && (
                                    <ActivationList
                                        items={items}
                                        isFilter={isFilter}
                                        isInitEmpty={isInitEmpty}
                                        meta={meta}
                                        filterRequest={filterRequest}
                                        setItems={setItems}
                                        updateList={updateList}
                                        setMeta={setMeta}
                                        getReservationsByIsActivated={getReservationsByIsActivated}
                                        setFilterRequest={setFilterRequest}
                                        setShimmer={setShimmer}
                                        shimmer={shimmer}
                                    />
                                )
                            }
                        ]}
                    />
                </Col>
            </Row>
        </>
    );
}
