import {makeAutoObservable} from "mobx";
import {ISessions} from "../core/models/Sessions";

class Sessions {
    [Symbol.toStringTag] = this.constructor.name;

    constructor() {
        makeAutoObservable(this);
    }

    private _items!: ISessions[];

    get items() {
        return this._items;
    }

    set items(value: ISessions[]) {
        this._items = value;
    }
}

export default Sessions;
