import React, {useContext, useState} from "react";
import {Button, Col, Icon, Row, Space, Typography} from "@bezlimit/bezlimit-ui";
import {observer} from "mobx-react";
import _ from "lodash";
import "./style.scss";
// services
import SessionsServise from "../../../../services/Sessions";
//core
import {ISessions} from "../../../../core/models/Sessions";
import {LayoutContext, ModalContext} from "../../../../core/Contexts";
//components
import DesctopShimmer from "../menu/desctopShimmer";
import MobileActive from "../menu/mobileActive";
import MobileNoActive from "../menu/mobileNoActive";
import ModalCloseAll from "../menu/mobileNoActive/modalCloseAll";

const styles = {
    background: "rgba(255, 255, 255, 0.05)",
    // @ts-ignore
    "-webkit-backdrop-filter": " blur(50px)",
    "backdrop-filter": " blur(50px)"
};

export default observer(function MobileMenuSessions({setVisibleMob}: IProps) {
    const contextLayout = useContext(LayoutContext);
    const [loading, setLoading] = useState(false);
    const contextModal = useContext(ModalContext);
    const sessions: ISessions[] = SessionsServise.items;
    const listLenght = _.chain(sessions)
        .filter(({is_active}) => !is_active)
        .valueOf();

    const onBackProfile = () => {
        setVisibleMob(false);
    };

    const onClickCloseAll = () => {
        _.chain(sessions)
            .filter(({is_active}) => !is_active)
            .map(async (i) => {
                setLoading(true);
                await SessionsServise.deleteSession(i.id);
                setLoading(false);
                onCancel();
            })
            .valueOf();
        setLoading(true);
    };

    const onCancel = () => {
        contextModal?.setVisible(false);
        contextModal?.setCssClass("");
    };

    const onModal = () => {
        contextModal?.setCssClass(" bl-delete-all-modal");
        contextModal?.setMaskStyle(styles);
        contextModal?.setVisible(true);
        contextModal?.setOnCancel(() => onCancel);
        contextModal?.setChildren(
            <ModalCloseAll onClickCloseAll={onClickCloseAll} onCancel={onCancel}/>
        );
    };

    return (
        <div className="bl-menu sidebar-profile-mobile">
            {!loading ? (
                <Typography as="div" className="list-sessions">
                    <Row className="row-group-btn-close">
                        <Col sm={22} xs={24} className="col-back">
                            {_.lte(contextLayout?.width, 767) ?? 0 ? (
                                <>
                                    <Icon name={"back-arrow"} width={28}/>
                                    <Button onClick={onBackProfile}>Назад в профиль</Button>
                                </>
                            ) : null}
                        </Col>
                    </Row>

                    <Space direction="vertical" size={24}>
                        <Typography as="div" className="block-basic">
                            <Typography as="div" className="title">
                                Это устройство
                            </Typography>
                            {_.chain(sessions)
                                .filter(({is_active}) => !!is_active)
                                .map((i) => (
                                    <React.Fragment key={i.id}>
                                        <MobileActive i={i}/>
                                    </React.Fragment>
                                ))
                                .valueOf()}
                        </Typography>
                        <Typography as="div" className="block-active-sessions">
                            <Typography as="div" className="title">
                                Активные сеансы
                            </Typography>
                            <Typography as="div" className="bl-active-sessions">
                                {_.chain(sessions)
                                    .filter(({is_active}) => !is_active)
                                    .map((i) => (
                                        <React.Fragment key={i.id}>
                                            <MobileNoActive
                                                i={i}
                                                loading={loading}
                                                setLoading={setLoading}
                                            />
                                        </React.Fragment>
                                    ))

                                    .valueOf()}
                            </Typography>
                        </Typography>

                        <Typography as="div" className="btn-close-group">
                            {!_.isEqual(listLenght.length, 0) ? (
                                <Button type="primary" onClick={onModal}>
                                    Завершить все сеансы
                                </Button>
                            ) : (
                                <Typography
                                    as="div"
                                    size={_.gte(contextLayout?.width, 767) ?? 0 ? 24 : 18}
                                    fontFamily="hauss"
                                >
                                    Сеансы не найдены
                                </Typography>
                            )}
                        </Typography>
                    </Space>
                </Typography>
            ) : (
                <DesctopShimmer/>
            )}
        </div>
    );
});

interface IProps {
    setVisibleMob: any;
}
