import React, {useContext} from "react";
import {Typography, Space} from "@bezlimit/bezlimit-ui";
import {observer} from "mobx-react";
import {useTranslation} from "react-i18next";
import NumberFormat from "react-number-format";
import _ from "lodash";
import "./style.scss";
// services
import BonusesService from "../../../../../services/BonusesService";
import {LayoutContext} from "../../../../../core/Contexts";

export default React.memo(
    observer(function Accrued() {
        const {t} = useTranslation();
        const contextLayout = useContext(LayoutContext);

        return (
            <Typography as="div" className="bl-mb-accrued">
                <Space size={4} direction="vertical">
                    <Typography
                        as="div"
                        className="sum"
                        size={
                            _.gte(contextLayout?.width, 768)
                                ? _.gte(BonusesService.processSum.toFixed(0).toString().length, 6)
                                    ? 25
                                    : 31
                                : _.gte(BonusesService.processSum.toFixed(0).toString().length, 7)
                                ? 21
                                : 28
                        }
                        fontFamily="alumna"
                        color="#BF69C0"
                    >
                        <NumberFormat
                            value={BonusesService.processSum.toFixed(
                                !(BonusesService.processSum % 1) ? 0 : 2
                            )}
                            displayType="text"
                            thousandSeparator=" "
                        />
                    </Typography>
                    <Typography size={17} color="rgba(255, 255, 255, 0.5)">
                        {t("Начисляются")}
                    </Typography>
                </Space>
            </Typography>
        );
    })
);
