import {Block, Col, Icon, Row, Space, Typography} from "@bezlimit/bezlimit-ui";
import {observer} from "mobx-react";
import "./style.scss";
import _ from "lodash";
import {useTranslation} from "react-i18next";
//core
import {ISessions} from "../../../../../core/models/Sessions";
import phoneProfile from "../../../../../images/phone_profile.png";

export default observer(function MobileActive({i}: Props) {
    const {t} = useTranslation();
    return (
        <Block className="bl-mobile-active">
            <Row>
                <Col span={3} className="col-img">
                    {_.isEqual(i.user_agent.type, "mobile") ? (
                        <img src={phoneProfile} width={18} alt="logo" />
                    ) : _.isEqual(i.user_agent.type, "web") &&
                    _.isEqual(i.user_agent.os_name, "linux") ? (
                        <Icon name={"linux_session"} size={"26px"}/>
                    ) : _.isEqual(i.user_agent.type, "web") &&
                    _.isEqual(i.user_agent.os_name, "windows") ? (
                        <Icon name={"Win_profile"} size={"26px"}/>
                    ) : (_.isEqual(i.user_agent.type, "web") &&
                        _.isEqual(i.user_agent.os_name, "macOS")) ||
                    _.isEqual(i.user_agent.os_name, "MacOS") ? (
                        <Icon name={"mac_session"} size={"26px"}/>
                    ) : _.isEqual(i.user_agent.type, "web") &&
                    _.isEqual(i.user_agent.os_name, "android") ? (
                        <Icon name={"android_session"} size={"26px"}/>
                    ) : _.isEqual(i.user_agent.type, "web") &&
                    _.isEqual(i.user_agent.os_name, "iOS") ? (
                        <Icon name={"apple_session"} size={"26px"}/>
                    ) : (
                        <Icon name={"other_session"} size={"26px"}/>
                    )}
                </Col>

                <Col span={21} className="col-content-block">
                    <Space direction="vertical" size={8}>
                        <Typography as="div" className="title-devices" size={16}>
                            {_.isEqual(i.user_agent.name, "")
                                ? "Неизвестное устройство"
                                : `${i.user_agent.name}, ${i.user_agent.os_name}`}
                        </Typography>
                        <Typography as="div" className="sub-title-devices" size={14}>
                            {i.user_agent.type}
                        </Typography>
                        {_.isEqual(i.location, null) || _.isEqual(i.location, "") ? (
                            <Typography as="div" className="discription-devices" size={14}>
                                {i.ip}
                                <Icon name={"dots"} size={"5px"}/>
                                {t("в сети")}
                            </Typography>
                        ) : (
                            <Typography as="div" className="discription-devices" size={14}>
                                {i.ip} - {i.location}
                                <Icon name={"dots"} size={"5px"}/>
                                {t("в сети")}
                            </Typography>
                        )}
                    </Space>
                </Col>
            </Row>
        </Block>
    );
});

interface IProps {
    i: ISessions;
}

type Props = IProps;
