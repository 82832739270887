import {Layout} from "antd";
import "./style.scss";
import Menu from "../menu";

export default function SiderProfile({collapsed, toggle}: Props) {
    return (
        <>
            <Layout.Sider
                trigger={null}
                collapsible
                collapsed={collapsed}
                collapsedWidth={0}
                width={634}
                className="bl-sider-profile"
            >
                <Menu toggle={toggle}/>
            </Layout.Sider>
            {!collapsed && <div className="sidebar-drop" onClick={toggle}/>}
        </>
    );
}

interface IProps {
    collapsed: boolean;
    toggle: () => void;
}

type Props = IProps;
