import {makeAutoObservable} from "mobx";

class Nps {
    [Symbol.toStringTag] = this.constructor.name;
    public phone: string | null = null;

    constructor() {
        makeAutoObservable(this);
    }
}

export default Nps;
