import {useContext, useEffect} from "react";
import {Button, Col, Icon, Row, Space, Typography} from "@bezlimit/bezlimit-ui";
import {notification} from "antd";
import _ from "lodash";
import "./style.scss";
// core
import {IReservations} from "../../../../../../core/models/Reservations";
import {LayoutContext, ModalContext} from "../../../../../../core/Contexts";
// components
import Phone from "../../../../numbers/list/default/info/phone";
import Description from "../../../reservationList/info/description";
import Status from "./status";
import NumberListInfoModal from "../../../../../modal/numberListInfo";
import {observer} from "mobx-react";
import RemoveSim from "../../../../../modal/removeSim";
import {createSearchParams, useNavigate, useSearchParams} from "react-router-dom";
import closeNPS from "../../../../../../images/delete_nps.svg";
import vectorNPS from "../../../../../../images/vector_nps.svg";
import NPS from "../../../../../../images/nps3.svg";
import Store from "../../../../../../stores";

export default observer(function Item({
    id,
    sim,
    phone_number: phone,
    pay_type,
    additional_data,
    removed_at,
    can_change_sim,
    nps,
    phone: {
        status,
        activated_at,
        unblock_sum,
        type,
        mask: {category},
        region: {name: regionName}
    },
    tariff: {
        id: tariffId,
        name: tariffName,
        minutes,
        internet,
        sms,
        prolong_paid_price: prolongPaidPrice
    }
}: IReservations) {
    const contextLayout = useContext(LayoutContext);
    const contextModal = useContext(ModalContext);
    const navigate = useNavigate();
    const el: any = document.body.querySelector(".ant-notification-notice-close");
    const [search] = useSearchParams();

    const onClick = () => {
        contextModal?.setChildren(
            <NumberListInfoModal
                phone={phone}
                tariffName={tariffName}
                minutes={minutes}
                internet={internet}
                type={type}
                sms={sms}
                id={id}
            />
        );
        contextModal?.setVisible(true);
    };

    const onRemoveSim = () => {
        contextModal?.setChildren(<RemoveSim phone={phone} sim={sim} id={id} />);
        contextModal?.setVisible(true);
    };
    let phoneNPS = "";
    if (additional_data?.nps) {
        phoneNPS = additional_data?.nps.phone;
    }

    const onCloseNotification = () => {
        if (el) {
            _.isEqual(window.location.pathname, "/activation") && el.click();
        }
        if (location.href.includes("?")) {
            history.pushState({}, "", location.href.split("?")[0]);
        }

        Store.nps.phone = null;
    };

    useEffect(() => {
        onCloseNotification();
    }, [el]);

    const notif = () => {
        Store.nps.phone = phone.toString();
        navigate({
            search: createSearchParams({
                nps: phone.toString()
            }).toString(),
            pathname: "/numbers"
        });
        openNotification();
    };

    const openNotification = () => {
        notification.open({
            message: (
                <Typography as="div" className="massege">
                    <Typography as="div">
                        {"Заверши бронирование по акции «Не плати за связь»"}
                    </Typography>
                </Typography>
            ),
            description: (
                <Typography as="div" className="description">
                    <img src={NPS} width={38} />
                    {phone}
                </Typography>
            ),
            className: "notification-nps",
            style: {
                width: 600
            },
            duration: 0,
            placement: "top",
            top: 20,
            onClick: () => {
                navigate({
                    search: createSearchParams({
                        nps: phone.toString()
                    }).toString(),
                    pathname: "/numbers"
                });
            },
            onClose: onCloseNotification,
            closeIcon: <img src={closeNPS} width={24} />,
            icon: <img src={vectorNPS} width={_.gte(contextLayout?.width, 431) ? 42 : 29} />
        });
    };

    return (
        <Typography
            as="div"
            className={`bl-activation-list-items-item${
                _.isEqual(pay_type, "super_link")
                    ? " bl-super_link"
                    : !_.isEqual(phoneNPS, "")
                    ? " bl-nps"
                    : ""
            }`}
        >
            <Row gutter={[16, _.gt(contextLayout?.width ?? 0, 768) ? 16 : 8]}>
                <Col
                    xs={12}
                    lg={
                        _.isEqual(can_change_sim, true) && _.isEqual(nps, true)
                            ? 12
                            : _.isEqual(can_change_sim, true) || _.isEqual(nps, true)
                            ? 15
                            : 11
                    }
                >
                    <Row gutter={16} className="left">
                        <Col
                            xs={24}
                            xl={
                                _.isEqual(can_change_sim, false) && _.isEqual(nps, true)
                                    ? 5
                                    : _.isEqual(can_change_sim, true) && _.isEqual(nps, false)
                                    ? 5
                                    : _.isEqual(can_change_sim, true) && _.isEqual(nps, true)
                                    ? 6
                                    : 7
                            }
                            className="phone"
                        >
                            <Space direction="horizontal" size={8}>
                                <Phone category={category} phone={phone} />
                                {_.lte(contextLayout?.width, 992) ? (
                                    <Icon
                                        className="info"
                                        name="info"
                                        size="16"
                                        onClick={onClick}
                                    />
                                ) : null}
                            </Space>
                        </Col>
                        <Col
                            xs={24}
                            xl={
                                _.isEqual(can_change_sim, true) && _.isEqual(nps, true)
                                    ? 18
                                    : _.isEqual(can_change_sim, false) && _.isEqual(nps, true)
                                    ? 19
                                    : _.isEqual(can_change_sim, true) && _.isEqual(nps, false)
                                    ? 19
                                    : 17
                            }
                            className="desc"
                        >
                            <Description
                                tariffName={tariffName}
                                minutes={minutes}
                                internet={internet}
                                sms={sms}
                                pay_type={pay_type}
                                regionName={regionName}
                                additional_data={additional_data}
                                phoneNPS={phoneNPS}
                            />
                        </Col>
                    </Row>
                </Col>
                <Col
                    xs={12}
                    lg={
                        _.isEqual(can_change_sim, true) && _.isEqual(nps, true)
                            ? 4
                            : _.isEqual(can_change_sim, true) || _.isEqual(nps, true)
                            ? 5
                            : 13
                    }
                    className="status"
                >
                    <Status
                        status={status}
                        activated_at={activated_at}
                        unblock_sum={unblock_sum}
                        pay_type={pay_type}
                    />
                </Col>
                {_.isEqual(nps, true) && (
                    <Col xs={24} lg={4}>
                        <Button className="btn-nps-notif" onClick={notif}>
                            Подключить скидку
                        </Button>
                    </Col>
                )}

                {_.isEqual(can_change_sim, true) && (
                    <Col xs={24} lg={4}>
                        <Button className="btn-remove-sim" onClick={onRemoveSim}>
                            Смена СИМ-карты
                        </Button>
                    </Col>
                )}
            </Row>
        </Typography>
    );
});
