import _ from "lodash";
import moment from "moment";
import {IReservation} from "../core/models/Phones";
// store
import Store from "../stores";
// services
import PhoneService from "./PhoneService";
import PhonesService from "./PhonesService";

class PhonesBookingService extends PhonesService {
    [Symbol.toStringTag] = this.constructor.name;
    protected _default: PhoneService = new PhoneService(Store.phones.booking.default);
    protected _paid: PhoneService = new PhoneService(Store.phones.booking.paid);
    protected _standard: PhoneService = new PhoneService(Store.phones.booking.standard);
    protected _internet: PhoneService = new PhoneService(Store.phones.booking.internet);

    async fetchAllInit() {
        this.cleanUp();
        await Promise.all([
            this.getObject("default").searchPhones({
                is_reserved: true,
                per_page: 6,
                sort: "-tariff_price"
            })
        ]);
    }

    private attributes(
        reservation: IReservation | undefined,
        cb: (reservation: IReservation) => boolean
    ) {
        if (!_.isNil(reservation)) {
            return cb(reservation);
        }
        return false;
    }

    isPaidType(reservation: IReservation | undefined) {
        return this.attributes(reservation, ({pay_type}) => {
            if (
                _.isEqual(pay_type, "order") ||
                _.isEqual(pay_type, "connection_paid") ||
                _.isEqual(pay_type, "bonus")
            ) {
                return true;
            }
            return false;
        });
    }

    isFreeTime(reservation: IReservation | undefined) {
        return this.attributes(reservation, ({pay_type}) => {
            if (_.isEqual(pay_type, "free")) {
                return true;
            }
            return false;
        });
    }

    isConnectionPaid(reservation: IReservation | undefined) {
        return this.attributes(reservation, ({pay_type}) => {
            if (_.isEqual(pay_type, "connection_paid")) {
                return true;
            }
            return false;
        });
    }

    isFreeBooking(reservation: IReservation | undefined) {
        return this.attributes(reservation, ({pay_type}) => {
            if (_.isEqual(pay_type, "free")) {
                return true;
            }
            return false;
        });
    }

    isLastCall(reservation: IReservation | undefined) {
        return this.attributes(reservation, ({pay_type, removed_at}) => {
            if (
                !_.isEqual(pay_type, "bonus") &&
                _.gt(moment().diff(removed_at, "milliseconds"), 0)
            ) {
                return true;
            }
            return false;
        });
    }

    isSuperLink(reservation: IReservation | undefined) {
        return this.attributes(reservation, ({pay_type}) => {
            if (_.isEqual(pay_type, "super_link")) {
                return true;
            }
            return false;
        });
    }

    isNps(reservation: IReservation | undefined) {
        return !!_.get(reservation, "additional_data.nps.phone");
    }

    isInProcess(reservation: IReservation | undefined) {
        return this.attributes(reservation, ({removed_at}) => {
            if (_.gt(moment().diff(removed_at, "milliseconds"), 0)) {
                return true;
            }
            return false;
        });
    }

    countDown(reservation: IReservation | undefined) {
        if (!_.isNil(reservation)) {
            const {removed_at} = reservation;
            const interval = 1000;
            const eventTime = moment(removed_at).unix();
            const currentTime = moment().unix();
            const diffTime = eventTime - currentTime;
            let duration: any = moment.duration(diffTime * 1000, "milliseconds");
            let res = "";

            setInterval(function () {
                duration = moment.duration(duration - interval, "milliseconds");
                // $(".countdown").text(
                res = duration.hours() + ":" + duration.minutes() + ":" + duration.seconds();

                // );
            }, interval);

            return res;
        }
    }
}

export default new PhonesBookingService();
