import React, {useContext, useState} from "react";
import {Block, Button, Col, Icon, Row, Space, Typography} from "@bezlimit/bezlimit-ui";
import {observer} from "mobx-react";
import _ from "lodash";
import "./style.scss";
//core
import {ISessions} from "../../../../core/models/Sessions";
import {LayoutContext} from "../../../../core/Contexts";
//services
import SessionsServise from "../../../../services/Sessions";
//components
import DesctopShimmer from "./desctopShimmer";
import AttentionSessions from "../../../../images/attention_sessions.png";
import DesctopActive from "./desctopActive";
import DesctopNoActive from "./desctopNoActive";

export default observer(function Menu({toggle}: Props) {
    const contextLayout = useContext(LayoutContext);
    const [visible, setVisible] = useState(false);
    const [simmer, setShimmer] = useState(false);
    const sessions: ISessions[] = SessionsServise.items;

    const listLenght = _.chain(sessions)
        .filter(({is_active}) => !is_active)
        .valueOf();

    const onClickModal = () => {
        setVisible(true);
    };

    const onCloseModal = () => {
        setVisible(false);
    };

    const onClickCloseAll = () => {
        _.chain(sessions)
            .filter(({is_active}) => !is_active)
            .map(async (i) => {
                setShimmer(true);
                await SessionsServise.deleteSession(i.id);
                setShimmer(false);
                setVisible(false);
            })
            .valueOf();
        setShimmer(true);
    };

    return (
        <div className="bl-menu sidebar-profile">
            {!visible ? (
                !simmer ? (
                    <Typography as="div" className="list-sessions">
                        <Row className="row-group-btn-close">
                            <Col sm={24} xs={0} className="col-close">
                                <Icon
                                    name={"close"}
                                    size={"26px"}
                                    className={"sidebar-close"}
                                    onClick={toggle}
                                />
                            </Col>
                        </Row>
                        <Space direction="vertical" size={24}>
                            <Typography as="div" className="block-basic">
                                <Typography as="div" className="title">
                                    Это устройство
                                </Typography>
                                {_.chain(sessions)
                                    .filter(({is_active}) => !!is_active)
                                    .map((i) => (
                                        <React.Fragment key={i.id}>
                                            <DesctopActive i={i} />
                                        </React.Fragment>
                                    ))

                                    .valueOf()}
                            </Typography>
                            <Typography as="div" className="block-active-sessions">
                                <Typography as="div" className="title">
                                    Активные сеансы
                                </Typography>

                                {_.chain(sessions)
                                    .filter(({is_active}) => !is_active)
                                    .map((i) => (
                                        <React.Fragment key={i.id}>
                                            <DesctopNoActive
                                                i={i}
                                                loading={simmer}
                                                setLoading={setShimmer}
                                            />
                                        </React.Fragment>
                                    ))
                                    .valueOf()}
                            </Typography>

                            <Typography as="div" className="btn-close-group">
                                {!_.isEqual(listLenght, []) ? (
                                    <Button type="primary" onClick={onClickModal}>
                                        Завершить все сеансы
                                    </Button>
                                ) : (
                                    <Typography
                                        as="div"
                                        size={_.gte(contextLayout?.width, 767) ?? 0 ? 24 : 18}
                                        fontFamily="hauss"
                                    >
                                        Сеансы не найдены
                                    </Typography>
                                )}

                                <Button type="text" onClick={toggle}>
                                    Закрыть
                                </Button>
                            </Typography>
                        </Space>
                    </Typography>
                ) : (
                    <DesctopShimmer />
                )
            ) : !simmer ? (
                <Block className="bl-modal-attention">
                    <Typography as="div" className="close-btn-modal">
                        <Icon
                            name={"close"}
                            size={"26px"}
                            className={"sidebar-close"}
                            onClick={onCloseModal}
                        />
                    </Typography>

                    <Row>
                        <Space direction="vertical" size={24}>
                            <Col span={24}>
                                <img src={AttentionSessions} width={88} alt="logo" />
                            </Col>
                            <Col span={24}>
                                <Typography as="div" className="title">
                                    Завершить все сеансы
                                </Typography>
                            </Col>
                            <Col span={24}>
                                <Typography as="div" className="sub-title">
                                    После завершения сеансов у вас останется один активный сеанс
                                </Typography>
                            </Col>

                            <Col span={24}>
                                <Button type="primary" onClick={onClickCloseAll}>
                                    Завершить все сеансы
                                </Button>
                            </Col>
                            <Col span={24}>
                                <Button type="text" onClick={onCloseModal}>
                                    Закрыть
                                </Button>
                            </Col>
                        </Space>
                    </Row>
                </Block>
            ) : (
                <DesctopShimmer />
            )}
        </div>
    );
});

interface IProps {
    toggle: () => void;
}

type Props = IProps;