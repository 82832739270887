import React, {useEffect, useRef, useState} from "react";
import {Carousel, Typography} from "@bezlimit/bezlimit-ui";
import _ from "lodash";
import "./style.scss";
// services
import NotificationsService from "../../../../services/NewsService";
// components
import BlNews from "./blNews";
import {observer} from "mobx-react";

export default React.memo(
    observer(function NewsSlider() {
        const ref = useRef<any>(null);
        const [currentIndex, setCurrentIndex] = useState(1);

        useEffect(() => {
            NotificationsService.fetchNews();
        }, []);

        return (
            <Typography as="div" className="bl-news-widget">
                <Carousel
                    autoplay={true}
                    autoplaySpeed={5000}
                    ref={ref}
                    speed={100}
                    effect="fade"
                    dots={true}
                    dotPosition="bottom"
                    arrows={false}
                    infinite={true}
                    adaptiveHeight={false}
                    onReInit={() => {
                        if (
                            ref.current &&
                            !_.isEqual(
                                _.get(ref.current, "innerSlider.track.props.currentSlide", 0),
                                currentIndex
                            )
                        ) {
                            ref.current.goTo(currentIndex);
                        }
                    }}
                    beforeChange={(currentSlide, nextSlide) => {
                        setCurrentIndex(nextSlide);
                    }}
                >
                    {_.chain(NotificationsService.items)
                        .orderBy(["published_date"], ["desc"])
                        .filter((i, k) => k < 3)
                        .map(({id, preview_text, published_date, title, preview_image}) => (
                            <Typography as="div" key={id}>
                                <BlNews
                                    id={id}
                                    preview_text={preview_text}
                                    published_date={published_date}
                                    title={title}
                                    preview_image={preview_image}
                                />
                            </Typography>
                        ))
                        .valueOf()}
                </Carousel>
            </Typography>
        );
    })
);
