import {useContext, useEffect} from "react";
import {observer} from "mobx-react";
import {useTranslation} from "react-i18next";
import {Button, Col, Icon, Row, Title, Typography} from "@bezlimit/bezlimit-ui";
//component
import {ProfileInfo} from "./profileInfo";
import {ConfirmEmail} from "./confirmEmail";
import {ConfirmPhone} from "./confirmPhone";
import {RejectPassport} from "./rejectPassport";
import {RegisterPassport} from "./registerPassport";
import PersonalManager from "../../blocks/widgets/personalManages";
// services
import UserService from "../../../services/UserService";
//store
import Store from "../../../stores";

import "./style.scss";
import SavingBankCards from "./savingBankCards";
import {isMIUI} from "react-device-detect";
import {LayoutContext, SessionContext} from "../../../core/Contexts";
import _ from "lodash";

export default observer(function Profile({setVisibleMob}: Props) {
    const {t} = useTranslation();
    const contextSession = useContext(SessionContext);
    const contextLayout = useContext(LayoutContext);

    const onClickModal = () => {
        setVisibleMob(true);
        window.scrollTo({
            top: 0,
            behavior: "smooth"
        });
    };

    const getUserInfo = async () => {
        await UserService.getUserLoyalty();
    };

    useEffect(() => {
        getUserInfo();
    }, []);

    return (
        <Typography as="div" className="bl-profile-page">
            <Title title={t("Профиль")}/>
            <Row gutter={[16, 16]}>
                <Col span={24}>
                    <ProfileInfo/>
                </Col>
                <Col span={24}>
                    <Button
                        onClick={
                            _.lte(contextLayout?.width, 520)
                                ? onClickModal
                                : contextSession?.toggleCollapsed
                        }
                        type="primary"
                        className={isMIUI ? "btn-miui" : "btn-sessions"}
                    >
                        <Icon name={"img-devices-session"} size={"30px"}/>
                        Устройства
                    </Button>
                </Col>
                <Col span={24}>
                    <SavingBankCards/>
                </Col>
                {Store.user.personal_data?.passport_status === "reject" && (
                    <Col span={24}>
                        <RejectPassport/>
                    </Col>
                )}
                {Store.user.personal_data?.phone_status !== "confirmed" && (
                    <Col xs={24} md={12} className="bl-profile-confirm">
                        <ConfirmPhone/>
                    </Col>
                )}
                {Store.user.profile?.email_status !== "confirmed" && (
                    <Col xs={24} md={12} className="bl-profile-confirm">
                        <ConfirmEmail/>
                    </Col>
                )}
                {Store.user.personal_data?.passport_status !== "confirmed" && (
                    <Col span={24}>
                        <RegisterPassport/>
                    </Col>
                )}
                <Col span={24}>
                    <PersonalManager title="Вас пригласил" isProfile={true}/>
                </Col>
            </Row>
        </Typography>
    );
});

interface IProps {
    setVisibleMob: any;
}

type Props = IProps;
